<template>
  <b-card-code no-body title="All questionnaires">
    <div class="m-2">
      <!-- Table Top -->
      <div class="d-flex align-items-center justify-content-between mt-2">
        <!-- Per Page -->
        <div cols="6" md="6">
          <!-- page length -->
          <b-form-group
            label="Show"
            label-cols="5"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
            
          </b-form-group>
        </div>
        <div>
          <b-form-input
              id="input-23"
              v-model="searchQuestionnaire"
              type="text"
              size="sm"
              placeholder="Search questionnaires"
              required
            ></b-form-input>
        </div>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="filteredList"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <!-- Column: Title -->
      <template #cell(title)="data">
        <router-link v-if="canManageQuestionnaires" :to="{name: 'questionnaire-single', params: {id: data.item.id} }">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.title | strippedHTML }}
          </span>
        </router-link>
        <span v-else class="font-weight-bold d-block text-nowrap">
            {{ data.item.title | strippedHTML }}
          </span>
      </template>

      <!-- Column: Subcategory -->
      <template #cell(subcategory.questionnairecategory.name)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.subcategory.questionnairecategory.name | strippedHTML }}
          </span>
      </template>
     
      <!-- Column: Available -->
      <template #cell(available)="data">
        <b-badge :variant="status[1][data.item.available]">
          {{ status[0][data.item.available] }}
        </b-badge>
      </template>

      <template #cell(delete)="data">
        <div v-if="data.item.subcategory.questionnairecategory.name != 'My mental health and my job/career'">
          <b-badge v-if="canManageQuestionnaires" variant="light-danger" @click="removeQuestionnaire(data.item.id)">
            <feather-icon icon="Trash2Icon" />
          </b-badge>
          <b-badge v-else variant="light-danger" >
            <feather-icon icon="Trash2Icon" />
          </b-badge>
        </div>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";

import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      canManageQuestionnaires: false,
      searchQuestionnaire: "",
      data: [],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      fields: [
        { key: "title", label: "Title" },
        { key: "subcategory.questionnairecategory.name", label: "Category" },
        { key: "available", label: "Available", class: "text-center" },
        { key: "delete", label: "Delete", class: "text-center" },
      ],
      status: [{
        0: 'No', 1: 'Yes'
      },
      {
        0: 'light-danger', 1: 'light-success',
      }],
    };
  },
  computed: {
      filteredList() {
        let searchTerm = this.searchQuestionnaire;
        let countQuestionnaires = 0

        return this.data.filter(questionnaire => {
            if(questionnaire.title.toLowerCase().includes(searchTerm.toLowerCase()) || questionnaire.subcategory.questionnairecategory.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              // Update total rows
              countQuestionnaires++
              this.totalRows = countQuestionnaires
              
              return questionnaire;
            }
        });       
      }
  },
  created() {
    if(store.state.app.userData.permissions.includes("Manage Questionnaires") || store.state.app.userData.permissions.includes("Manage Everything")) {
      this.canManageQuestionnaires = true;
    }
    this.getAllQuestionnaires();
  },
  methods: {
    getAllQuestionnaires() {
      //Return questionnaires list
      this.$http.get(
          "/api/auth/questionnaires"
        )
        .then((res) => {
          if(res != undefined) {
            this.data = res.data
            // Set the initial number of items
            this.totalRows = this.data.length;
          }
        })
        .catch((err) => {
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong, please login again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
          });
        })
    },
    removeQuestionnaire(questionnaire_id) {
      this.$bvModal
          .msgBoxConfirm('All user records, questions and answers related to this questionnaire will be deleted!.', {
          title: 'Are your sure?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
      }).then(value => {        
          if(value == true) {
              this.$http.delete("/api/auth/admin/questionnaire/" + questionnaire_id)
              .then((response) => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "The questionnaire was successfully deleted!",
                          icon: "CheckSquareIcon",
                          variant: "success",
                      },
                  });     
                  this.getAllQuestionnaires();                 
              }).catch((error) => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "There was an error deleting the questionnaire!",
                          icon: "AlertCircleIcon",
                          variant: "danger",
                      },
                  });
              });
          }
      })
    }
  }
};
</script>

<style scoped>

</style>